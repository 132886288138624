/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --btn-text-transform: none;
  --btn-text-case: none;
}

html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}
